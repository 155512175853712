<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16"
  >
    <v-overlay v-if="loading">
      <v-row
        class="mt-12"
      >
        <v-col class="d-flex justify-center">
          <ellipse-progress
            :loading="loading"
            :size="180"
            img-src="src/assets/images/termlife-icon.png"
            thickness="5"
            empty-thickness="3"
            line-mode="out 5"
            legend="false"
            animation="rs 700 1000"
            font-size="1.5rem"
            color="#00A1B7"
            empty-color-fill="transparent"
            filename="termlife-icon.png"
          >
          </ellipse-progress>
        </v-col>
      </v-row>
      <v-row v-if="applicationStep>=7">
        <v-col
          class="d-flex justify-center"
          style="color:rgb(0 224 255);text-align: center;
          font-size: 1.2rem;
          padding: 1.5rem;"
        >
          {{ $t('common.finalLoadingText') }}
        </v-col>
      </v-row>
    </v-overlay>
    <v-row
      justify="center"
      class="pb-16"
    >
      <v-col
        cols="12"
        md="12"
      >
        <base-material-card
          icon="mdi-file-document-outline"
          color="primary"
        >
          <template v-slot:after-heading>
            <div class="font-weight-light card-title mt-2">
              {{ $t("common.openApplications") }}
            </div>
          </template>
          <v-row>
            <v-col class="d-flex justify-center">
              <div class="primary--text text-center text-h1 mt-2 mb-4">
                {{ $t("menu.myApplications") }}
              </div>
            </v-col>
          </v-row>
          <div>
            <v-row class="d-flex justify-center">
              <v-col
                cols="12"
                sm="10"
                md="10"
              >
                <v-data-table
                  :headers="headers"
                  :items="applications"
                >
                  <template v-slot:item.applicationStep="{item}">
                    <v-progress-linear
                      :value="item.applicationStep*10"
                    ></v-progress-linear>
                  </template>
                  <template v-slot:item.action="{item}">
                    <v-icon
                      color="primary"
                      @click="edit(item)"
                    >
                      mdi-pencil
                    </v-icon>&nbsp;
                    <v-icon
                      color="error"
                      @click="deleteItem(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </div>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import firebase from 'firebase/app'
  import moment from 'moment'
  import { currentApplications } from '@/utils/common'
  import { applicationSteps } from '../../../utils/common'

  export default {
    name: 'UserApplications',
    components: {},
    data () {
      return {
        headers: [
          { text: this.$i18n.t('caTerm.basicInfo.fullName'), value: 'name' },
          { text: this.$i18n.t('caTerm.basicInfo.email'), value: 'emailAddress' },
          { text: this.$i18n.t('caTerm.basicInfo.dateOfBirth'), value: 'dob' },
          { text: this.$i18n.t('common.applicationProgress'), value: 'applicationStep' },
          { text: this.$i18n.t('caTerm.beneficiaries.actions'), value: 'action', sortable: false },
        ],
        loading: true,
        userId: null,
        applications: [],
      }
    },
    watch: {
      async userId (val, oldVal) {
        console.log({ val, oldVal })
        if (val !== oldVal && val !== null) {
          await this.getApplications(val)
          this.loading = false
        }
      }
    },
    mounted () {
      this.userId = this.$store.getters?.user?.id
    },
    methods: {
      async getApplications (val) {
        this.loading = true

        const ref = firebase.database().ref(`users/${val}/applications/`)
        const snapshot = await ref.once('value')
        const data = snapshot.val()
        if (data) {
          this.applications = currentApplications(data)
        } else {
          this.applications = []
        }
        this.loading = false
      },
      edit (item) {
        this.$router.push(`/canadianterm/survey/${applicationSteps[item.applicationStep]}/${item.id}`)
      },
      async deleteItem (item) {
        try {
          this.loading = true
          await firebase.database().ref(`users/${this.$store.getters.user.id}/applications/${item.id}`).remove()
          await this.getApplications(this.userId)
        } catch (e) {
          console.log('Error fetching application', e)
        } finally {
          this.loading = false
        }
      }
    },
  }
</script>
<style lang="scss" scoped>
.share-btn {
  background-image: linear-gradient(to right bottom, $color-green-light, $color-green);
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}

.header-txt {
  font-family: roboto, sans-serif !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  letter-spacing: 0.4px !important;
  color: #757575 !important;
}

.provider-txt {
  font-family: poppins, sans-serif !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 20px !important;
  color: #212121 !important;
}

.dark-grey {
  color: rgba(0, 0, 0, 0.6);
}

::v-deep .v-data-table thead tr th {
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  letter-spacing: 0.4px !important;
  color: #757575 !important;
}

::v-deep .v-data-table tbody tr td {
  font-family: 'Roboto' !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  letter-spacing: 0.4px !important;
  color: #212121 !important;
}

.faded-green {
  color: rgba(76, 175, 80, 0.15);
}

.card {
  perspective: 150rem;
  -moz-perspective: 150rem;
  border: none !important;
  position: relative;
  height: 22rem;
  max-width: 400px;

  &__details {
    padding-top: 1rem;

    ul {
      list-style: none;
      width: 90%;

      li {
        font-size: 1.25rem;
        color: gray;
        padding: .5rem;
        border-bottom: 1px solid #eee;
      }
    }
  }
}

::v-deep .v-dialog {
  overflow: hidden;
}
</style>
